import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {IGridRequestPayload, IPagedResponse} from "@shared/components/base/ag-base-grid/ag-base-grid.interface";
import {Observable} from "rxjs";
import {environment} from "@env/environment";
import {map, share} from "rxjs/operators";
import {IMultiSelectItem} from "@core/interfaces/select-item";
import {IUpdateSubsystemParams} from "@core/interfaces/subsystem";
import {FileService} from "@core/services/file.service";

@Injectable({
  providedIn: 'root'
})
export class AdminSubsystemsApiService {

  constructor(
    private HttpClient: HttpClient,
    private fileService: FileService
  ) {
  }

  private subsystemsListRequestPayload: IGridRequestPayload = {
    sortings: [{field: "id", direction: "asc", index: 0}],
    filters: {'is_system': false}
  };

  private subsystemsListActiveRequestPayload: IGridRequestPayload = {
    sortings: [{field: "id", direction: "asc", index: 0}],
    filters: {'is_active': true, 'is_system': false}
  };

  public getSubsystemsGrid(requestPayload: IGridRequestPayload): Observable<IPagedResponse> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/subsystem/grid`,
      requestPayload
    ).pipe(share());
  }

  public getSubsystemsList(requestPayload: IGridRequestPayload = this.subsystemsListRequestPayload)
    : Observable<IMultiSelectItem[]> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/subsystem/grid`,
      requestPayload
    ).pipe(
      map((item: IPagedResponse) => {
        const list = item && item.elements && item.elements.length > 0 ? item.elements : [];
        const result: IMultiSelectItem[] = list && list.length > 0
          ? list.map((l) => {return {key: l.id, value: l.name};}) : [];
        return result;
    }), share());
  }

  public getActiveSubsystemsList(requestPayload: IGridRequestPayload = this.subsystemsListActiveRequestPayload)
    : Observable<IMultiSelectItem[]> {
    return this.HttpClient.post<IPagedResponse>(
      `${environment.API_URL}/subsystem/grid`,
      requestPayload
    ).pipe(
      map((item: IPagedResponse) => {
        const list = item && item.elements && item.elements.length > 0 ? item.elements : [];
        const result: IMultiSelectItem[] = list && list.length > 0
          ? list.map((l) => {return {key: l.id, value: l.name};}) : [];
        return result;
      }), share());
  }

  public addSubsystem(params: IUpdateSubsystemParams) {
    return this.HttpClient.post(`${environment.API_URL}/subsystem/create`, params).pipe(share());
  }

  public updateSubsystem(params: IUpdateSubsystemParams) {
    return this.HttpClient.put(`${environment.API_URL}/subsystem/update`, params).pipe(share());
  }

  public deleteSubsystem(id: number) {
    return this.HttpClient.delete(`${environment.API_URL}/subsystem/delete/${id}`).pipe(share());
  }

  // получить иконку подсистемы
  public getIcon(subsystemId: number): Observable<HttpResponse<Blob>> {
    return this.fileService.getPhoto(
      `${environment.API_URL}/subsystem/${subsystemId}/icon`
    ).pipe(share());
  }

  // добавление иконки подсистемы
  public addIcon(subsystemId: number, file: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);

    return this.HttpClient.post<any>(
      `${environment.API_URL}/subsystem/${subsystemId}/icon`,
      formData
    ).pipe(share());
  }

  // удаление иконки подсистемы
  public deleteIcon(subsystemId: number): Observable<any> {
    return this.HttpClient.delete<any>(
      `${environment.API_URL}/subsystem/${subsystemId}/icon`,
    ).pipe(share());
  }

}
