import {AbstractControl, ValidatorFn} from "@angular/forms";
import {IValidator} from "@core/interfaces/validator";

export function EmailValidator(): ValidatorFn {
  return (control: AbstractControl): IValidator => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = control.value && control.value.length > 0 ? re.test(control.value) : true;
    return isValid ? null : { 'email': true };
  }
}
